import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description:
    'Onboarding thousands of developers into the Ethereum ecosystem.',
  title: 'ETHGlobal',
  url: 'http://ethglobal.co',
  author: 'ETHGlobal',
  keywords: ['ethereum', 'global'],
  twitter: {
    id: '@ethglobal',
    img: 'https://ethglobal.co/og.png',
  },
  facebook: {
    id: '',
    img: 'https://ethglobal.co/og.png',
  },
};

const SEO = ({ params }) => {
  params = Object.assign({}, SEO_DATA, params);
  return (
    <Helmet>
      <meta property="fb:app_id" content={params.facebook.id} />
      <meta property="og:title" content={params.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={params.url} />
      <meta property="og:image" content={params.facebook.img} />
      <meta property="og:description" content={params.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={params.twitter.id} />
      <meta name="twitter:site" content={params.twitter.id} />
      <meta name="twitter:title" content={params.title} />
      <meta name="twitter:description" content={params.description} />
      <meta name="twitter:domain" content={params.url} />
      <meta name="twitter:image:src" content={params.img} />

      <meta name="description" content={params.description} />
      <meta name="keywords" content={params.keywords.join(', ')} />
      <meta name="author" content={params.author} />
      <title>{params.title}</title>
      <html lang="en" />
    </Helmet>
  );
};

export default SEO;
