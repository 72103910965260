import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const ExternalLink = ({ href, children, ...other }) => (
  <OutboundLink
    href={href}
    target={"_blank"}
    rel="noreferrer noopener"
    {...other}
  >
    {children}
  </OutboundLink>
);

export default ExternalLink;
